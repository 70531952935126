import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">

        <img src='https://firebasestorage.googleapis.com/v0/b/estudiokoraju-bf778.appspot.com/o/WhatsApp%20Image%202024-04-29%20at%208.03.36%20PM%20(1).jpeg?alt=media&token=1798457d-1d17-4a86-83b4-8b0d9acd0f4b' className="App-logo" alt="logo" />
       
    </div>
  );
}

export default App;
